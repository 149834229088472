import store from '@/store';
import {
  Admin, ReqHomesDocument, Conversation, IConversation,
} from '@/models';
import { checkStringEquality } from '@/util/funcs';
import { getSnapshotWithReferenceId } from '@/core/user';

const sortByDate = (data: Admin[], sortBy: number) => data.sort((a: any, b: any) => {
  if (sortBy === -1) {
    return Date.parse(b.conversation?.lastMessageDate) - Date.parse(a.conversation?.lastMessageDate);
  }
  return Date.parse(a.conversation?.lastMessageDate) - Date.parse(b.conversation?.lastMessageDate);
});

const generateHomeData = () => {
  const home = store.getters.selectedHome as ReqHomesDocument & { timelineDates : any };
  const homeData = {
    id: home.id,
    name: home.name,
    address: home.address,
    lotNumber: home.lotNumber,
    block: home.block,
    zip: home.zip,
    divisionName: home.division.name,
    communityName: home.community.name,
    communityReference: getSnapshotWithReferenceId('communities', home.communityId),
    divisionReference: getSnapshotWithReferenceId('divisions', home.divisionId),
    closedDate: home.timelineDates?.closedDate ?? '',
  };
  return homeData;
};

const generateAdminData = (activeConversationInfo: Admin) => ({
  id: activeConversationInfo!.id,
  firstName: activeConversationInfo!.firstName,
  lastName: activeConversationInfo!.lastName,
  email: activeConversationInfo!.email,
  designation: activeConversationInfo!.designation,
  roles: activeConversationInfo!.roles,
});

const sortAdmins = (adminList: Admin[]) => {
  const sortedList = [] as Admin[];
  const entriesWithConversations = [] as Admin[];
  adminList.forEach((admin) => {
    if (admin.conversation && Object.keys(admin.conversation).length && admin.conversation.lastMessageDate) {
      admin.allReadMessagesFlag = false;
      entriesWithConversations.push(admin);
    } else {
      admin.allReadMessagesFlag = true;
      sortedList.push(admin);
    }
  });
  const conversationsSortedByDate = sortByDate(entriesWithConversations, 1);
  conversationsSortedByDate.forEach((entry) => {
    sortedList.unshift(entry);
  });
  return sortedList;
};

const mapConversationsWithAdmins = async (conversations: IConversation[], admins: Admin[]) => {
  conversations.forEach((conversation) => {
    const adminId = conversation.adminData.id;
    const adminIndex = admins.findIndex((admin) => checkStringEquality(admin.id, adminId));
    if (adminIndex > -1) {
      admins[adminIndex].conversation = (new Conversation(conversation));
    } else {
      const admin = new Admin(Admin.getAdminData(conversation.adminData));
      admin.conversation = (new Conversation(conversation));
      admins.push(admin);
    }
  });
  return admins;
};

export {
  sortAdmins,
  mapConversationsWithAdmins,
  generateAdminData,
  generateHomeData,
  sortByDate,
};
