import { IAnnouncement } from '@/models';
import { updateFirestoreRecordField } from '@/core/user';
import Home from '@/util/home';

const updateLastAnnouncementRead = (homeId: string) => {
  const isoDate = (new Date()).toISOString();
  updateFirestoreRecordField('homes', homeId, 'lastAnnouncementRead', isoDate).then((res) => {
    Home.setHomeLastAnnouncementRead(isoDate);
  });
};

const checkForUpdateRecords = (records: IAnnouncement[]) => {
  const lastUpdatedDate = Home.getHomeLastAnnouncementRead();
  if (lastUpdatedDate) {
    const lastCheckedDate = new Date(lastUpdatedDate);
    let announcementCount = 0;
    records.forEach((data: IAnnouncement) => {
      const createDate = new Date(data.createdAt);
      if (lastCheckedDate < createDate) {
        announcementCount += 1;
      }
    });
    return announcementCount;
  }
  return records.length;
};

export {
  updateLastAnnouncementRead, checkForUpdateRecords,
};
