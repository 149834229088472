<template>
 <section class="w-full block  rounded-xl bg-white shadow-modal animate-pulse p-4 pb-5 lg:p-6 lg:pb-8 relative h-[192px]">
   <div class="w-full flex flex flex-col mdlg:items-center mdlg:flex-row justify-between">
      <span class="w-32 h-4 mdlg:h-6 bg-wrh-gray-extralight mb-2"></span>
      </div>
      <ul class="space-y-2 mt-4">
        <li class="flex  bg-wrh-gray-extralight px-4 py-3 rounded-lg">
          <div class=" w-8 h-8 rounded-full bg-white"></div>
          <div class="space-y-1 flex flex-col ml-2 w-full">
            <span class="w-20 h-3 bg-white"></span>
            <span class="w-full h-2 bg-white"></span>
            <span class="w-8 h-1 bg-white"></span>
          </div>
        </li>
      </ul>
  </section>
</template>
