<template>
  <section
    class="
      h-[calc(100%-216px)]
      w-full
      block
      animate-pulse
      p-4
      pb-5
      lg:p-6 lg:pb-8
      relative
      bg-white
      shadow-modal
      rounded-xl
      pr-3
      pb-4
      mdlg:mt-6
    "
  >
    <div
      class="w-full flex flex mdlg:items-center mdlg:flex-row justify-between"
    >
      <span class="w-32 h-4 mdlg:h-6 bg-wrh-gray-extralight mb-2"></span>
      <span class="w-20 ml-5 h-4 mdlg:h-6 bg-wrh-gray-extralight mb-2"></span>
    </div>
    <ul class="space-y-2 mt-4">
      <li class="flex bg-wrh-gray-extralight px-4 py-3 rounded-lg">
        <div class="w-8 h-8 rounded-full bg-white"></div>
        <div class="space-y-1 flex flex-col ml-2 w-full">
          <span class="w-20 h-3 bg-white"></span>
          <span class="w-15 h-2 bg-white"></span>
          <span class="w-full h-2 bg-white"></span>
          <span class="w-8 h-1 bg-white"></span>
        </div>
      </li>
      <li class="flex bg-wrh-gray-extralight px-4 py-3 rounded-lg">
        <div class="w-8 h-8 rounded-full bg-white"></div>
        <div class="space-y-1 flex flex-col ml-2 w-full">
          <span class="w-20 h-3 bg-white"></span>
          <span class="w-15 h-2 bg-white"></span>
          <span class="w-full h-2 bg-white"></span>
          <span class="w-8 h-1 bg-white"></span>
        </div>
      </li>
      </ul>
  </section>
</template>
