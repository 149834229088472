import { onUnmounted, ref } from 'vue';
import {
  collection, getFirestore, query, where, getDocs, onSnapshot,
} from 'firebase/firestore';
import * as _ from 'lodash';
import { IAnnouncementFirestoreData, IAnnouncement } from '../models/announcement';
import { Collections, StoreActions } from '@/constants';
import store from '@/store';

const orderByDate = (announcements: IAnnouncementFirestoreData[]) => announcements.sort((a, b) => {
  if (a.updatedAt > b.updatedAt) return -1;
  return 1;
});

const filterAnnouncements = (announcements:IAnnouncementFirestoreData[]) => {
  const reqAnnouncements = [] as IAnnouncement[];
  announcements.forEach((announcement) => {
    const reqAnnouncement = {} as IAnnouncement;
    reqAnnouncement.description = announcement.description ? announcement.description : '';
    reqAnnouncement.uploadedByFirstName = announcement.createdBy.firstName;
    reqAnnouncement.uploadedByLastName = announcement.createdBy.lastName;
    reqAnnouncement.uploadedBy = `${announcement.createdBy.firstName} ${announcement.createdBy.lastName}`;
    reqAnnouncement.uploadedAt = announcement.updatedAt;
    reqAnnouncement.id = announcement.id;
    reqAnnouncement.title = announcement.title;
    reqAnnouncement.createdAt = announcement.createdAt;
    reqAnnouncements.push(reqAnnouncement);
  });
  return reqAnnouncements;
};

export const fetchAnnouncementsData = async (divisionId?: string, communityId?: string) => {
  const db = getFirestore();
  const announcements = ref<IAnnouncementFirestoreData[]>([]);
  const announcementQuery = query(collection(db, Collections.ANNOUNCEMENTS), where('division.id', '==', divisionId) || where('community.id', '==', communityId));
  const querySnapshot = await getDocs(announcementQuery);
  querySnapshot.forEach((doc) => {
    announcements.value.push({ id: doc.id, ...doc.data() } as IAnnouncementFirestoreData);
  });
  const uniqueAnnouncements = orderByDate(_.uniqBy(announcements.value, 'id') as IAnnouncementFirestoreData[]);
  return filterAnnouncements(uniqueAnnouncements);
};

export const getSnapAnnouncements = (divisionId?: string, communityId?: string) => {
  const db = getFirestore();
  const announcementQuery = query(collection(db, Collections.ANNOUNCEMENTS), where('division.id', '==', divisionId) || where('community.id', '==', communityId));
  const newChangedAnnouncement = ref<IAnnouncementFirestoreData[]>([]);
  const newAnnouncement = ref<IAnnouncement[]>([]);
  const unsubscribe = onSnapshot(announcementQuery, async (snapshot) => {
    newChangedAnnouncement.value = [];
    await snapshot.docChanges().forEach(async (change) => {
      if (change.type !== 'removed') {
        newChangedAnnouncement.value.push({ id: change.doc.id, ...change.doc.data() } as IAnnouncementFirestoreData);
      }
      newAnnouncement.value = filterAnnouncements(newChangedAnnouncement.value);
    });
    await store.dispatch(StoreActions.SET_ANNOUNCEMENTS_SKELETON, true);
  });
  onUnmounted(unsubscribe);
  return { newAnnouncement };
};

export const message = 'nothing to show yet in announcements';
